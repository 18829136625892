import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { Link } from "gatsby";
import classNames from "classnames";
import { colorModes } from "../../../utils/common";
import { Context } from "../../../context/Context";
import { Menu } from "../Menu";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const { colorMode, setColorMode } = useContext(Context);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      lessons: allSanityLesson(
        filter: {
          publishedAt: { ne: "null" }
          slug: { current: { ne: "null" } }
        }
        sort: { publishedAt: ASC }
      ) {
        edges {
          node {
            title
            lessonDisplayName {
              en
              uk
              _type
            }
            slug {
              current
            }
            slideshows {
              title
              slideshowDisplayName {
                en
                uk
                _type
              }
              slug {
                current
              }
              id
            }
            id
          }
        }
      }
    }
  `);

  const lessons = data.lessons;
  // useEffect(() => {
  //   setColorMode(colorModes[1]);
  // }, []);

  const link = location.pathname.split("/")[1];

  // const cls = classNames({ [styles.darkMode]: link });
  const cls = classNames({ [styles.lightMode]: link }, styles.header);

  const lessonMenuItems = lessons.edges.map(({ node }, index) => ({
    to: "#" + node.slug.current,
    label: node.lessonDisplayName,
    type: "lesson",
  }));

  const menuItems = [
    {
      to: "",
      label: { en: "Home", uk: "Головна" },
      type: "mainMenuItem",
    },
    {
      to: "about",
      label: { en: "About", uk: "про" },
      type: "mainMenuItem",
    },
    {
      to: "resources",
      label: { en: "Resources", uk: "Ресурси" },
      type: "mainMenuItem",
    },
  ];
  return (
    <header className={cls}>
      <h2 id="mainmenulabel" className="visually-hidden">
        Main Menus
      </h2>
      <Menu items={[...menuItems, ...lessonMenuItems]} location={location} />
      {/* <nav aria-labelledby="mainmenulabel" className={styles.nav}>
        <ul>
          <li className={styles.name}>
            <Link to="/" activeClassName="active">
              LEARN HTML and more...
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">
              about
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="active">
              about
            </Link>
          </li>
        </ul>
      </nav> */}
    </header>
  );
};

export { Header };
