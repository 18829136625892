// extracted by mini-css-extract-plugin
export var active = "Menu-module--active--05a10";
export var background = "Menu-module--background--4cac5";
export var iconPlaceholder = "Menu-module--icon-placeholder--f330d";
export var languages = "Menu-module--languages--730b6";
export var lesson = "Menu-module--lesson--52156";
export var locationSwitch = "Menu-module--location-switch--8f7e4";
export var menuButton = "Menu-module--menuButton--6e07c";
export var menuItem = "Menu-module--menu-item--c6823";
export var nav = "Menu-module--nav--a0a77";
export var navInner = "Menu-module--nav-inner--f532c";
export var navigation = "Menu-module--navigation--125e2";
export var refresh = "Menu-module--refresh--53fce";
export var textPlaceholder = "Menu-module--text-placeholder--29ba2";