import React, { useContext } from "react";
import { Link } from "gatsby";
import { Context } from "../../context/Context";
import { languages } from "../../utils/locale";
import * as styles from "./Menu/Menu.module.scss";

const LocationSwitch = ({ location, toggle }) => {
  const { setLanguage } = useContext(Context);

  // switch the language context when the language links are clicked
  const switchLanguage = (language) => {
    setLanguage(language);
  };

  let path = location.pathname;

  let checkString = path?.substring(0, 3);

  if (checkString === "/uk") {
    path = path.replace("/uk", "");
  }

  //   if (checkString === "/sc") {
  //     path = path.replace("/sc", "");
  //   }

  let englishPath = path;
  let ukranianPagePath = "/uk" + path;
  //   let simplifiedChinesePagePath = "/sc" + path;

  return (
    <div className={styles.languages}>
      <h3>
        <Link
          to={englishPath}
          onClick={() => {
            switchLanguage(languages.ENGLISH);
            toggle();
          }}
        >
          English
        </Link>
      </h3>
      <h3>
        <Link
          to={ukranianPagePath}
          onClick={() => {
            switchLanguage(languages.UKRAINIAN);
            toggle();
          }}
        >
          🇺🇦 Ukrainian
        </Link>
      </h3>
    </div>
  );
};

export { LocationSwitch };
