export const colorModes = ["light", "dark"];

export function toPlainText(blocks = []) {
  return (
    blocks
      // loop through each block
      .map((block) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== "block" || !block.children) {
          return "";
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join("");
      })
      // join the paragraphs leaving split by two linebreaks
      .join("\n\n")
  );
}

export function trimLongString(string, length) {
  if (string.length > length) {
    return (string = string.substring(0, length - 1) + "...");
  } else {
    return string;
  }
}

export function findEmoji(lessons, id) {
  const flatSlideshows = lessons.flatMap(({ node }) =>
    node.slideshows.map((slideshow) => slideshow.id)
  );
  return flatSlideshows.findIndex((s) => s == id);
}

export const smoothScrollTo = (el, timerDelay = 0) => {
  // const scrollOffset =
  //   getCSSCustomProp("--header-height", document.documentElement, "number") -
  //   getCSSCustomProp("--border-width", document.documentElement, "number")

  const timerId = setTimeout(() => {
    window.scrollTo({
      top: el.offsetTop,
      // top: el.offsetTop - scrollOffset,
      behavior: "smooth",
    });
  }, timerDelay);

  return () => clearTimeout(timerId);
};
