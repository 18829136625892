export const languages = {
  ENGLISH: {
    name: "English",
    label: "EN",
    path: "",
    locale: "en",
  },
  UKRAINIAN: {
    name: "Ukrainian",
    label: "UK",
    path: "uk",
    locale: "uk",
  },
  // TRADITIONAL_CHINESE: {
  //   name: "Tradiontal Chinese",
  //   label: "繁體",
  //   path: "tc",
  // },
  // SIMPLIFIED_CHINESE: {
  //   name: "Simplified Chinese",
  //   label: "简体",
  //   path: "sc",
  // },
};

export const createLocaleTextGetter = (languageCode) => {
  const languages = [languageCode, "en"]; // last language in array is default;
  const localize = (value) => {
    if (!value) return;
    if (Array.isArray(value)) {
      return value.map((v) => localize(v, languages));
    } else if (typeof value == "object") {
      // this fixes a span error
      if (value?._type === "span") {
        return value;
      }
      // carry on translating
      if (/^locale[A-Z]/.test(value?._type)) {
        const language = languages.find((lang) => value[lang]);
        return value[language];
      }
      return Object?.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages);
        return result;
      }, {});
    }
    return value;
  };
  return localize;
};

export const localizeField = (fieldObj, languageCode) => {
  if (languageCode.length === 0) languageCode = "en";
  return fieldObj[languageCode];
};

export const languageFromPath = (path) => {
  const checkString = path?.substring(0, 4);

  switch (checkString) {
    case languages.SIMPLIFIED_CHINESE.path + "/":
      return languages.SIMPLIFIED_CHINESE;
    case languages.TRADITIONAL_CHINESE.path + "/":
      return languages.TRADITIONAL_CHINESE;
  }
  return languages.ENGLISH;
};
