import React from "react";
import { LocationSwitch } from "../LocationSwitch";
import * as styles from "./Footer.module.scss";

const Footer = ({ className, location }) => {
  const link = location?.pathname.split("/")[1];

  // if (link) return false;

  return (
    <footer className={styles.footer}>
      <div>
        Creative coding is created for educational purposes only, all images are
        intended fair use in this context.
      </div>
    </footer>
  );
};

export { Footer };
