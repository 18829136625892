import { useEffect, useRef, useState } from "react";

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useDimensions = (ref) => {
  const dimensions = useRef({ width: 0, height: 0 });

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  }, []);

  return dimensions.current;
};

export const useMediaQuery = (mqs, initial = false) => {
  const [matches, setMatches] = useState(initial);

  useEffect(() => {
    const mq = window.matchMedia(mqs);
    const cb = (e) => {
      setMatches(e.matches);
    };
    setMatches(mq.matches);

    mq.addListener(cb);

    return () => mq.removeListener(cb);
  }, [mqs]);

  return matches;
};

export const MediaQuery = {
  LargeMobile: "(min-width: 35em)",
  Tablet: "(min-width: 48em)",
  Desktop: "(min-width: 60em)",
  Widescreen: "(min-width: 75em)",
};
