import React from "react";
import smoothscroll from "smoothscroll-polyfill";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/variables/colors.css";
import "./src/styles/variables/general.css";
import "./src/styles/variables/sizes.css";
import "./src/styles/variables/type.css";
import "./src/styles/global.scss";

// import "@fontsource/ibm-plex-sans"; // This contains ALL variable axes. Font files are larger.
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/200.css";
import "@fontsource/ibm-plex-sans/200-italic.css"; // Italic variant.

import "@fontsource/ibm-plex-mono/400.css";

import Layout from "./src/components/layout/Layout";
import { Provider } from "./src/context/Context";

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>;
};

export const onClientEntry = () => {
  smoothscroll.polyfill();
};
