import React, { createContext, useContext, useState, useEffect } from "react";

import { colorModes } from "../utils/common";
import { languages } from "../utils/locale";

const defaultValues = {
  colorMode: colorModes[0],
  setColorMode: () => false,
  language: languages.ENGLISH,
  setLanguage: () => false,
};

export const Context = createContext(defaultValues);

export const Provider = ({ children }) => {
  const [colorMode, setColorMode] = useState(colorModes[0]);
  const [language, setLanguage] = useState(languages.ENGLISH);
  const [currentLesson, setCurrentLesson] = useState(0);
  const [currentSlideShow, setCurrentSlideShow] = useState(0);

  useEffect(() => {
    colorModes.forEach((cm) => {
      document.documentElement.classList.toggle(`${cm}-mode`, cm === colorMode);
    });
  }, [colorMode]);

  return (
    <Context.Provider
      value={{
        colorMode,
        setColorMode,
        language,
        setLanguage,
        currentLesson,
        setCurrentLesson,
        currentSlideShow,
        setCurrentSlideShow,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCodeContext = () => useContext(Provider);
