import React, { useRef } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions, MediaQuery, useMediaQuery } from "../../../utils/hooks";
import { MenuToggle } from "./MenuToggle";
import { Navigation } from "./Navigation";

import * as styles from "./Menu.module.scss";

const Menu = ({ items, location }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const isTablet = useMediaQuery(MediaQuery.Tablet);

  const sidebar = {
    open: {
      transition: {
        type: "spring",
        stiffness: 10,
        damping: 200,
        restDelta: 2,
      },
      // width: "100%",
    },
    closed: {
      transition: {
        delay: 0.2,
        type: "spring",
        stiffness: 90,
        damping: 20,
        restDelta: 2,
      },
      // width: "80px",
    },
  };

  return (
    <motion.nav
      className={styles.nav}
      initial={false}
      animate={isOpen ? "open" : "closed"}
      // custom={height}
      ref={containerRef}
    >
      {/* <motion.div className={styles.background} variants={sidebar} /> */}
      <Navigation
        items={items}
        toggle={() => toggleOpen()}
        variants={sidebar}
        location={location}
      />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

export { Menu };
