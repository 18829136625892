import React, { useContext } from "react";
import { Link } from "gatsby";
import { LangLink } from "../../common/LangLink";
import { motion } from "framer-motion";
import { localizeField } from "../../../utils/locale";
import { Context } from "../../../context/Context";
import * as styles from "./Menu.module.scss";

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const colors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF", "#4400FF"];

export const MenuItem = ({ item, toggle }) => {
  const style = { border: `2px solid ${colors[item]}` };
  const { language } = useContext(Context);
  const type = item.type;
  return (
    <motion.li
      className={`${styles.menuItem} ${styles[type]}`}
      variants={variants}
      // whileHover={{ scale: 1.1 }}
      // whileTap={{ scale: 0.95 }}
    >
      {/* <h2> */}
      <LangLink
        to={`/${item.to}`}
        className={styles.menuLink}
        style={style}
        onClick={toggle}
      >
        {localizeField(item.label, language.locale)}
      </LangLink>
      {/* </h2> */}
    </motion.li>
  );
};
